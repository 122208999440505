import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './style/pfapp.scss';

import Login from 'pages/Login';
import Chat from 'pages/Chat';
import NotValidTab from 'pages/NotValidTab';
import { PrivateRoute } from 'routes/privateRoute';

const App = () => {
  const [modules, setModules] = useState([])


const renderChat = () => {
  return (<Route path='/Chat' element={<Chat />} />)
}
  const renderLogin = () => {
    return (<Route index element={<Login />} />)
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Outlet />}>
            <Route element={<PrivateRoute modules={modules} />}>
              {renderLogin()}
              {renderChat()}
            </Route>
            {/* catch all */}
            <Route path='*' element={<NotValidTab />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position='top-right' autoClose={3000} />
    </>
  );
};

export default App;
