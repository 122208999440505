import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { includes } from 'lodash';

import Header from './Header';
import Subheader from './Subheader';
import LoaderChat from 'components/LoaderChat';
import StateStore from 'globalStates/Store';
import AppStore from 'context';
import useContextService from 'hooks/useContextService';
import Sider from './Sider';
import { Row, Col } from 'reactstrap';
import Chat from 'pages/Chat';

const Next = () => {
  const location = useLocation();
  const { states, resetStates } = useContextService();
  const { loading } = states;

  useEffect(() => {
    const { pathname } = new URL(window.location.href);
    const hasPathChanged = !includes(pathname, '/chat');
    if (hasPathChanged) {
      resetStates('chat');
    }
  }, [location]);

  return (
    <>
      {/* {loading && <LoaderChat opacity={0.01} color='black' />} */}
      <Outlet />
    </>
  );
};

const Layout = (props) => {
  const { modules } = props;
  return (
    <AppStore>
      <StateStore>
        <section>
          <Header />
          <Subheader />
          <div className='content-wrapper bg-light'
          style={{height:"auto",overflow:"hidden"}}
          >
            <Next />
          </div>
        </section>
      </StateStore>
    </AppStore>
  );
};

export default Layout;
