import Chat from 'pages/Chat';
import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import useAxios from 'hooks/useAxios';
import editicon from 'assets/icon/editicon.svg';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


// let arr = [{id:1,abbr: "CP", name:"Contract Pharmacy"},
// {id:2, abbr: "SB", name:"Split Billing"},
// {id:3, abbr: "HR", name:"HR Handbook"},
// {id:4, abbr: "MF", name:"Manufacturer Restrictions SOP"},
// {id:5, abbr: "AM", name:"Account Management SOP"},
// ]


const LeftPane = ({ onLeftPaneClick, onChatClick, isReloadChat, db, resetReload, updateCollapse,initMsg,setInitMsg ,setTableClick,tableclick}) => {
  const API = useAxios();
  const [activeId, setActiveId] = useState("GA");
  const [arr, setArr] = useState([]);
  const [bar, setBar] = useState(true);
  const [charData, setChatData] = useState([]);
  const [chatName, setChatName] = useState("");
  const [showDownArrow, setShowDownArrow] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(true);
  const [terms, setTerms] = useState("")
  const initialMsg = [
    {
        message: "Hello, I'm Kareem, your AI assistant. How can I help you?",
        isAgent: false
    }
];
  // console.log(terms,"terms")


  const toggle1 = () => { setModal(!modal), setModal1(false) };

  const toggle = () => setModal(!modal);
  const toggleOk = () => { getTermsPost(), setModal(!modal) };

  useEffect(() => {
    if (terms == true) {
      getDocuments();
    }
  }, [terms]);
  const getTerms = async () => {
    // msg.append("term_status":"accept");

    try {


      const { data } = await API.get("terms");

      setTerms(data?.status)


      // console.log(data,"data")
    } catch (error) {
      // setLoad(false)
    }
  }
  useEffect(() => {
    getTerms()
  }, [])
  const getTermsPost = async () => {
    let msg = new FormData()
    msg.append("term_status", "accept");

    try {


      const { data } = await API.post("terms", msg);
      console.log(data, "data")
      if (data?.status == 200) {
        getDocuments()
        getTerms()
      }

      // console.log(data,"data")
    } catch (error) {
      // setLoad(false)
    }
  }


  useEffect(() => {
    if (isReloadChat === true) {
      getChats(db);
      resetReload(false);
    }
  }, [isReloadChat]);


  const getDocuments = async () => {
    try {
      //setLoading(true);
      const { data } = await API.post(`get_documents/`);
      if (data) {
        //setLoading(false);
        setArr(data.documents)

      } else {
        //setLoading(false);
        setArr([]);
      }
    } catch (error) { setArr([]); }
  }

  const handleClick = (val) => {


    if (!showDownArrow && val.abbreviation == db) {
      setShowDownArrow(true);
    }
    else {
      setShowDownArrow(false);
    }
    //setShowDownArrow(false);
    setActiveId(val.abbreviation);
    setBar(!bar);
    onLeftPaneClick(val.abbreviation, chatName);
    getChats(val.abbreviation);
  }

  const getChats = async (val) => {

    setChatData([]);
    try {
      const { data } = await API.post(`get_chats/${val}`);
      if (data) {

        if (isReloadChat)
          setChatName(data.chats[0].name);


        setChatData(data.chats);
      } else {
        setChatData([]);
        setChatName(chatName)
      }
    }
    catch (error) { setChatData([]); }

  }

  const handleChatClick = (name,abb) => {
    if(abb=="CT"){
      setChatName(name)
      setTableClick(name)
      setInitMsg(initialMsg)
      onChatClick(name);

      
    }
    else{
      setTableClick("")
      setChatName(name);
      onChatClick(name);
  
    }

  }

  const handleNewNoteClick = (name) => {

    setChatName('');
    onChatClick('');
  }
  const handleClickCollapse = () => {
    updateCollapse();
  }
  useEffect(() => {
    toggle()
  }, [])
  return (

    <div className='nav_grid'>
      <div className='nav_bg' style={{ height: "100vh" }}>
        <h1>Chat Agents</h1>
        <div className='list_scroll'>
          <ul className='nav_list'
            style={{ overflow: "auto" }}
          >

            {/* 
        {arr && arr.map((val) => (
         <li style={{display:'inline-flex'}}>
           <div className='d-flex justify-content-between'>
            <a onClick={() => handleClick(val)} className={val.abbreviation == activeId ? "active" : ""}>
              {val.description}
            </a>
            <span><img title='New chat' src={editicon} alt="" height={50} /></span>
            </div>
          </li> 
        ))} */}

            {arr && arr.map((val) => {
              let abbreviation=val.abbreviation
              return(
              <div>
                <li>
                  <div className='d-flex justify-content-between w-100'>
                    <a onClick={() => handleClick(val)} className={val.abbreviation == activeId ? "active" : ""}>
                      {val.description}
                      <span><i className={(!showDownArrow && val.abbreviation == activeId) ? "fa fa-angle-up" : "fa fa-angle-down"} aria-hidden="true"></i></span>
                    </a>
                    <span hidden={val.abbreviation != activeId} onClick={() => handleNewNoteClick(val)}><img title='New chat' src={editicon} alt="" height={23} hidden ={val.abbreviation == "CT"}/>
                    </span>
                  </div>
                </li>
                <div hidden={(!showDownArrow && val.abbreviation == activeId) ? false : true} className='history_list'>
                  <small className='chat_history' hidden ={val.abbreviation == "CT"}>Chat History</small>
                  {charData && charData.map((val) => {
                  if(activeId=="CT"){
                    return(
                      <p onClick={(e) => handleChatClick(val.name,abbreviation)} className={val.name == tableclick ? "active" : ""}>{val.name}</p>
                            )
                  }
                  else{
return(
                    <p onClick={(e) => handleChatClick(val.name,abbreviation)} className={val.name == chatName ? "active" : ""}>{val.name}</p>
                          )
                  }
                  
                    
                  }
                    
                  )}

                </div>
              </div>
)
})}
          </ul>
        </div>
      </div>
      <div className='collapse-icon'>
        <a><i class="fa fa-angle-left" aria-hidden="true" onClick={handleClickCollapse}></i></a>
      </div>
      {terms === false ?
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle1}>Terms and Conditions</ModalHeader>
          <ModalBody>
            This conversation is with an AI language model, not a human. While we strive for accuracy,
            responses may contain errors or biases. Do not rely on this chat for critical decisions
            involving legal, medical, financial, or personal matters. Your privacy is important;
            avoid sharing sensitive personal information. Content may be monitored for quality
            improvement. Use of this chatbot is subject to our Terms of Service and Privacy Policy.
            By accessing or using our AI chat service, you agree to be bound by these Terms of Service. <a href="#">Terms and Conditions</a>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleOk}>Accept</Button>{' '}

          </ModalFooter>
        </Modal>
        : ""
      }
    </div>

  );
}

export default LeftPane;