import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import useAxios from 'hooks/useAxios';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import LoaderChat from 'components/LoaderChat';
import uparrow from 'assets/icon/uparrow.svg';
import useContextService from 'hooks/useContextService';

let chtname = '';
let hideloading = true;
const RightPane = ({ db, initMsg, chatName, reloadChat, num ,setTableClick,tableclick}) => {

    const API = useAxios();
    const [chatList, setChatList] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [query, setQuery] = useState("");
    const [isQuestionHidden, setIsQuestionHidden] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const { states, resetStates } = useContextService();
    const { loading } = states;


    useEffect(() => {

        if (chatName != "") {
            getUserHistory();
            setLoad(false);
        }
        else {
            setChatList(initMsg);
            setLoad(false);
        }
        setQuery("");
        chtname = '';
    }, [chatName, num]);

    useEffect(() => {
        //   if(chatList.length>0)

        //     {
        //         var len = chatList.length;
        //         for(var i=0;i<len;i++)
        //         {
        //             chatList.pop(i);
        //         }
        //     }
        setChatList(initMsg);
        setIsQuestionHidden(false);
        setQuery("");
        // getQuestions();
        getUserHistory();
        handleClick();
        setLoad(false);

    }, [db]);

    const getUserHistory = async () => {

        try {
            let msg = new FormData();
            msg.append("chat_name", chatName);

            const { data } = await API.post(`get_history/${db}`, msg);
            if (data) {

                if (data.chat_history.length > 0) {
                    let exist = [];
                    for (var x in data.chat_history) {
                        var item = {};
                        item.isAgent = true;
                        item.message = data.chat_history[x].question;
                        exist.push(item);
                        item = {};
                        item.isAgent = false;
                        item.message = data.chat_history[x].answer;
                        exist.push(item);
                    }
                    setChatList(exist);
                }
                else {
                    setChatList(initMsg);
                }
            } else {
                setChatList(initMsg);
            }
        } catch (error) { setChatList(initMsg); }
    }

    // const getQuestions = async () => {
    //     try {
    //         setLoading(true);
    //         const { data } = await API.post(`qtop/${db}`);
    //         if (data) {
    //             setLoading(false);
    //             setQuestions(data.top_questions);

    //         } else {
    //             setLoading(false);
    //             setQuestions("");
    //         }
    //     } catch (error) { setLoading(false); setQuestions([]); }
    // }

    const handleClick = async () => {
        if (query === "") {
            return;
        }
        hideloading = false;
        var item = {};
        item.isAgent = true;
        item.message = query;
        setLoad(true);
        chatList.push(item);
        setChatList(chatList);

        try {
            let msg = new FormData();
            msg.append("user_message", query);
            msg.append("chat_name", chtname != '' ? chtname : chatName);
            const { data } = await API.post(`chat/${db}`, msg);
            if (data) {

                var res = {};
                hideloading = true;
                res.message = data.reply_message;
                res.isAgent = false;
                chtname = data.chat_name;
                chatList.push(res);
                setChatList(chatList);
                setQuery("");
                setQuestions([]);
                setIsQuestionHidden(true);
                reloadChat();
            } else {
                setQuery("");
                hideloading = true;
            }
        } catch (error) {
            setQuery("");
            hideloading = true;
        }
    }


    const handleQuestionQueryClick = async (query) => {
        if (query === "") {
            return;
        }
        var item = {};
        item.isAgent = true;
        item.message = query;
        chatList.push(item);
        setChatList(chatList);

        try {
            let msg = new FormData();
            msg.append("user_message", query);
            const { data } = await API.post(`chat/${db}`, msg);
            if (data) {
                var res = {};
                res.message = data.reply_message;
                res.isAgent = false;
                chatList.push(res);
                setChatList(chatList);
                setQuery("");
                setQuestions([]);
                setIsQuestionHidden(true);
            } else {
                setQuery("");
            }
        } catch (error) { setQuery(""); }
    }


    const handleQuery = (e) => {
        setQuery(e.target.value);
    }

    const handleKeyUp = (e) => {
        if (e.keyCode == 13) handleClick();
    }

    const handleUpDownClick = async (message, val,chatName) => {
        try {
            let msg = new FormData();
            msg.append("answer", message);
            msg.append("feedback", val);
            msg.append("chat_name", chtname != '' ? chtname : chatName);
            const { data } = await API.post(`feedback/${db}`, msg);
            if (data) {
                toast.success(data.reply_message);
            } else {
                toast.error(data.reply_message);
            }
        } catch (error) { }
    }

    const handleQuestionClick = (item) => {
        setQuery(item);
        handleQuestionQueryClick(item);
    }

    const handleOnMouseUp = () => {
        handleClick();
    }

    return (
        <div>
{(loading && load == false) && <LoaderChat opacity={0.01} color='black' />}
            <div className='card_list'>
                {/* <Row hidden={isQuestionHidden}>
                    {loading && <LoaderChat />}
                    <ul>
                        {
                            questions && questions.map(item => {
                                return (
                                    <li className='qu_info' onClick={(e) => handleQuestionClick(item.question)}>
                                        {item.question}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Row> */}
                <div className="card my-0">
                    <div className="card-body chat-care">
                        <ul className={'chat'}>
                            {
                                chatList && chatList.map(item => {
                                    return (
                                        <li style={{ width: "min-content !important" }} className={item.isAgent ? 'agent' : 'admin'}>
                                            {item.isAgent && <span className='chat-img left'>You</span>}
                                            {!item.isAgent && <span className='chat-img left'>AI</span>}
                                            <div className={'chat-body'}>
                                                <div dangerouslySetInnerHTML={{ __html: item.message }} />
                                                <span className="arrowright"></span>
                                            </div>
                                            {!item.isAgent && (
                                                <div className='feedback_icon'>
                                                    <small><i className="fa fa-thumbs-o-up" aria-hidden="true" onClick={() => handleUpDownClick(item.message, "correct",chatName)}></i></small>
                                                    <small><i className="fa fa-thumbs-o-down" aria-hidden="true" onClick={() => handleUpDownClick(item.message, "incorrect",chatName)}></i></small>
                                                </div>
                                            )}
                                        </li>
                                    )

                                })
                            }

                            <li className="admin" hidden={hideloading}>
                                <div className="snippet">
                                    <div className="stage">
                                        <div className="dot-elastic"></div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div className="card-footer">
                        <div className="input-group">
                            <input value={query} onChange={(e) => handleQuery(e)} onKeyUp={(e) => handleKeyUp(e)} className='form-control' type="text" id="user-input" placeholder="Type a message..."></input>
                            <span className="input-group-btn">
                                <button onClick={handleClick} className="btn" id="">
                                    <img src={uparrow} alt="" height={50} />
                                </button>
                            </span>
                        </div>
                        <small>This is an AI-powered chat, please do not share sensitive information or rely on it for critical decisions.<a href=''></a></small>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RightPane;