import axios from 'axios';
import useContextService from 'hooks/useContextService';
import { useEffect } from 'react';

const getSessionStorageDetails = () => {
  return JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
};

let instance = '';

const useAxios = () => {
  let lamaChecked =sessionStorage.getItem("changeport")||false;

  const { setStates } = useContextService();

  const setLoader = (hideLoader, loading) => {
    if (!hideLoader) setStates('loading', loading);
  };
  if (instance === '') {
    instance = axios.create({
      baseURL: `${process.env.REACT_APP_ENDPOINT}`,
      responseType: 'json',
      headers: {
        'Content-type': 'application/json',
      },
    });
    instance.interceptors.request.use(
      (config) => {
        console.log(config,"in use",config.hasDefaultURL)
        setLoader(config.hideLoader, true);
        const { token_type, access_token } = getSessionStorageDetails();
        config.headers.Authorization = `${token_type} ${access_token}`;
        if (config.hasDefaultURL) {
          console.log("in if ")
          config.url = `${config.baseURL}/${config.url}`;
        } else {
          console.log("in else ")
          if(lamaChecked=="false"|| lamaChecked==false)
          {
             config.url = `${config.baseURL}/${config.url}`;
          }
          else
          {
            // config.url = `${config.baseURL.replace("oldchat","newchat")}/${config.url}`;
            config.url = `${config.baseURL}/${config.url}`;
          }
          
        }

        return config;
      },
      (error) => {
        setLoader(error.config.hideLoader, false);
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        setTimeout(() => {
          setLoader(response.config.hideLoader, false);
        }, 250);
        return response;
      },
      (error) => {
        setLoader(error.config.hideLoader, false);
        return Promise.reject(error);
      }
    );

    return instance;
  }

  return instance;
};

export default useAxios;
