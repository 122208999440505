import React, { useState, useEffect, useContext } from 'react';
import { Button, FormGroup, Label, Row, Col, Input } from "reactstrap";
import useAxios from 'hooks/useAxios';
import LeftPane from './components/leftpane';
import RightPane from './components/rightpane';

const initialMsg = [
    {
        message: "Hello, I'm Kareem, your AI assistant. How can I help you?",
        isAgent: false
    }
];

const Chat = () => {
    const API = useAxios();
    const [initMsg, setInitMsg] = useState(initialMsg);
    const [activeId, setActivewId] = useState("GA");
    const [chatName, setChatName] = useState("");
    const [isReloadChat, setIsReloadChat] = useState(false);
    const [num, setNum] = useState(0);
    const [hide, sethide] = useState(false);
    const[tableclick,setTableClick]=useState("")


    const onLeftPaneClick = (val, chatname) => {
        setChatName(chatname)
        setActivewId(val);
        setInitMsg([
            {
                message: "Hello, I'm Kareem, your AI assistant. How can I help you?",
                isAgent: false
            }
        ]);
    }
    const reloadChat = () => {
        setIsReloadChat(true);
    }
    const resetReload = (val) => {
        setIsReloadChat(val);
    }
    const getUserHistory = async () => {
        try {
            let msg = new FormData();
            msg.append("chat_name", chatName);

            const { data } = await API.post(`get_history/${activeId}`, msg);
            if (data) {
                if (data.chat_history.length > 0) {
                    let exist = [];
                    for (var x in data.chat_history) {
                        var item = {};
                        item.isAgent = true;
                        item.message = data.chat_history[x].question;
                        exist.push(item);
                        item = {};
                        item.isAgent = false;
                        item.message = data.chat_history[x].answer;
                        exist.push(item);
                    }
                    setInitMsg(exist);
                }
                else {
                    setInitMsg(initMsg);
                }
            } else {
                setInitMsg(initMsg);
            }
        } catch (error) { }
    }


    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    const handleClick = () => {
        setNum(randomNumberInRange(1, 20));
    };
    const onChatClick = (val) => {

        setChatName(val);
        if (val == "") {
            setInitMsg([
                {
                    message: "Hello, I'm Kareem, your AI assistant. How can I help you?",
                    isAgent: false
                }
            ]);
            handleClick();
        }
        else {
            getUserHistory();
        }

    }

    return (

        <div>
            <div className='collapse-icon' hidden={!hide}>
                <a><i class="fa fa-angle-right" aria-hidden="true" onClick={() => sethide(!hide)}></i></a>
            </div>
            <Row>
                <Col md={hide == true ? 0 : 3} hidden={hide}>
                    <LeftPane onLeftPaneClick={onLeftPaneClick}  setInitMsg={setInitMsg} initMsg={initMsg} onChatClick={onChatClick} db={activeId} isReloadChat={isReloadChat} resetReload={resetReload} tableclick={tableclick}  setTableClick={setTableClick} updateCollapse={() => sethide(!hide)} />
                </Col>
                <Col md={hide == true ? 12 : 8}>
                    <RightPane db={activeId} initMsg={initMsg} chatName={chatName} reloadChat={reloadChat} num={num} tableclick={tableclick} setTableClick={setTableClick} />
                </Col>
            </Row>
        </div>
    );
}

export default Chat;